import './App.css';

function App() {
  const PROD = process.env.NODE_ENV === 'production';
  // TEST PATH
  // const path = "/home/list/414aecf2-76e0-4689-95fc-56fd14192db0"
  const path = window.location.pathname
  const link = PROD ? `pickeasy:/${path}` : `exp://192.168.2.11:19000/--${path}`
  return (
    <div className="App">
      <header className="App-header">
        <script>{window.location.replace(link)}</script>
        <h3>Redirecting you to PickEasy... <a href={link} style={{textDecoration: 'none', color: '#e21f70'}}>click here</a> if it doesn't work</h3>
      </header>
    </div>
  );
}

export default App;
